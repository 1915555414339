<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <!--
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Compañía (*):</label>
                <input type="text" class="form-control col-md-6" v-model="item.cmp_id" disabled="true">
              </div>
            </div>
          </div>
        -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Código (*):</label>
                <input type="String" class="form-control col-md-8" v-model="item.ser_cdgo" required autofocus>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Nombre (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.ser_nmbre" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Serie padre:</label>
                <input type="text" class="form-control col-md-8" v-model="item.ser_nodo">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Tiempo de respuesta (*) :</label>
                <input type="number" class="form-control col-md-8" v-model="item.ser_tres" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Índice predeterminado :</label>
                <input type="text" class="form-control col-md-8" v-model="item.ind_cdgo">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Proceso (*):</label>
                <select class="form-control col-md-8" v-model="item.prc_id" required>
                  <option v-for="i in procesos" v-bind:value="i._id">
                    {{ i.prc_cdgo + " - " + i.prc_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row">
            <div class="col-md-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-md-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-md-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        procesos: []
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.fetchProcesos();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear una Serie';
        this.buttontext = 'Agregar Serie';
      } else {
        this.title = 'Editar una Serie';
        this.buttontext = 'Actualizar Serie';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      fetchProcesos()
      {
        let uri = '/procesos/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.procesos = response.data;
        });
      },
      saveItem(){
        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/series/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            this.$router.replace({ name: 'DisplaySerie' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/series/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplaySerie'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/series/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplaySerie'});
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
